/* Fade Slider */
.swiper {
  width: 100%;
  height: 80vh;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-transparent-bg {
  background-color: #ededed;
}
.swiper-btn :hover {
  background-color: "#54aee6";
  color: "white";
}
.mySwiper2 {
  height: 80%;
  width: 100%;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: white;
  font-size: 5.5vh !important;
  padding: 6px 9px;
  background-color: #e63146;
  border-radius: 10px;
}

@media only screen and (max-width: 400px) {
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 19px !important;
  }
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  color: black;
}
.swiper-pagination-bullet-active {
  background: black;
}

/* Radio Buttons */
input[type="radio"]:checked + label {
  color: #54aee6;
  font-size: larger;
}

/* banner image */
.banner-caption {
  width: 50%;
}

@media only screen and (max-width: 450px) {
  .banner-caption {
    width: 75%;
  }
}

/* main-swiper */
.main-swiper {
  height: auto;
}
/* category-slider */
.category-slider {
  max-height: 50vh;
}
/* product-slider */
.product-slider {
  /* height: auto; */
}

.category-header {
  width: 100%;
  font-family: sans-serif;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  background-color: white;
}
.category-header:before,
.category-header:after {
  position: absolute;
  top: 50%;
  overflow: hidden;
  width: 50%;
  height: 4px;
  content: "\a0";
  background-color: black;
}
.category-header:before {
  margin-left: -50%;
  text-align: right;
}
.html-renderer {
  font-family: Poppins, sans-serif;
}
.html-renderer img {
  width: 100%;
}

/* Sale Button */

/* CSS */
.button-53 {
  background-color: white;
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.75rem;
  padding: 0.75rem 1.65rem;
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  text-decoration-thickness: auto;
  width: 100%;
  max-width: 460px;
  position: relative;
  cursor: pointer;
  /* transform: rotate(-2deg); */
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-53:focus {
  outline: 0;
}

.button-53:after {
  content: "";
  position: absolute;
  border: 1.5px solid #000000;
  bottom: 4px;
  left: 4px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

.button-53:hover:after {
  bottom: 2px;
  left: 2px;
}

@media (min-width: 768px) {
  .button-53 {
    padding: 0.75rem 3rem;
    font-size: 1.25rem;
  }
}
.datePicker {
  width: 90.5%;
  padding: 15.5px 14px;
  border: 1.5px solid #e63146;
  border-radius: 4px;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  outline: none;
}

.datePicker:focus {
  border: 1.5px solid #e63146;
}
